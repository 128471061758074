import React from "react";

const FooterSection = () =>
{
    return (
      <>
        <footer>
          <div className="all-page-border2 border-bottom-0">
            <p className="reserved">
              {" "}
              Nairobi Template © 2024, All Rights Reserved.
            </p>
          </div>
        </footer>
      </>
    );
};
export default FooterSection;